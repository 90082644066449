button, input[type="button"], input[type="reset"], input[type="submit"],
.titlebar .triangled_colored_separator,
.widget-area .widget-title::after,
.carousel-wrapper h2.title::after,
.course-item .course-thumbnail .price,
.site-footer .footer-social,
.single-lp_course .lp-single-course ul.learn-press-nav-tabs .course-nav.active,
.single-lp_course .lp-single-course ul.learn-press-nav-tabs .course-nav:hover,
.widget_tag_cloud a:hover,
.header-top .header-contact-wrapper .btn-secondary,
.header-type3 .header-top .header-contact-wrapper .box-icon,
a.btn-all-courses:hover,
.course-grid-layout2 .intro-item:before,
.learnpress .learn-press-pagination .page-numbers > li span,
.courses-search-widget::after
 { background: #9363cc; }

a:hover, a:focus, a:active,
.main-navigation a:hover,
.nav-menu ul li.current-menu-item a,
.nav-menu ul li a:hover,
.entry-title a:hover,
.main-navigation .current_page_item > a, .main-navigation .current-menu-item > a, .main-navigation .current_page_ancestor > a, .main-navigation .current-menu-ancestor > a,
.entry-meta span i,
.site-footer a:hover,
.blog .entry-header .entry-date, .archive .entry-header .entry-date,
.site-footer .copyright-area span,
.breadcrumbs a:hover span,
.carousel-wrapper .slick-arrow:hover:before,
.recent-post-carousel .post-item .btn-readmore:hover,
.recent-post-carousel .post-item .recent-news-meta span i,
.recent-post-carousel .post-item .entry-title a:hover,
.single-lp_course .course-info li i,
.search-form .search-submit,
.header-top .header-contact-wrapper li .box-icon i,
.course-grid-layout2 .intro-item .all-course a:hover,
.course-filter div.mixitup-control-active, .course-filter div:hover
{
    color: #9363cc;
}

.recent-post-carousel .post-item .btn-readmore:hover,
.carousel-wrapper .slick-arrow:hover,
.single-lp_course .lp-single-course .course-curriculum ul.curriculum-sections .section-header,
.widget_tag_cloud a:hover,
.readmore a:hover,
a.btn-all-courses,
.learnpress .learn-press-pagination .page-numbers > li span,
.course-filter div.mixitup-control-active, .course-filter div:hover {
    border-color: #9363cc;
}
.container { max-width: 1230px; }
.site-branding .site-logo, .site-logo { max-width: 175px; }

.topbar .cart-contents { color: #fff; }
.topbar .cart-contents i, .topbar .cart-contents { font-size: 14px; }

.titlebar { background-color: #8224e3; padding-top: 6%; padding-bottom: 6%; background-image: url(https://www.tr3spsicologia.centelica.com/wp-content/uploads/2021/04/cropped-pexels-photo-256417.jpeg); background-repeat: no-repeat; background-size: cover; background-position: center center;  }


.site-title,.site-description {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
}
.btn-validator {
    color: #fff;
    background-color: #9363cc;
    border-color: #9363cc;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 13px 20px;
    cursor: pointer;
    text-shadow: none;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    letter-spacing: 2px;
    float: right;
}